import React from 'react';
import { Box, Link, Typography, Container } from '@mui/material';

const footerTextStyle = {
  color: 'white',
  fontSize: 16,
  display: 'block',
  mb: 1,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
};

const sectionHeaderStyle = {
  color: 'white',
  fontSize: 20,
  fontWeight: 500,
  mb: 2,
};

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 6,
        bgcolor: '#0B2948',
        color: 'white',
        textAlign: 'center',
        fontFamily: 'SF Pro',
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: { xs: 'center', md: 'flex-start' },
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            <Box
              component="img"
              src="/assets/images/home/footer-logo.svg"
              alt="Footer Slogan"
              sx={{
                maxWidth: 80,
                height: 'auto',
                mb: 2,
              }}
            />
            <Typography
              sx={{
                mb: 4,
                color: 'white',
                fontSize: 20,
                fontWeight: 700,
                fontFamily: 'Barlow',
                textAlign: 'center',
              }}
            >
              PERFORMANCE - POTENTIAL - PRODUCTIVITY
            </Typography>
          </Box>

          <Box sx={{ textAlign: { xs: 'center', md: 'left' }, mb: { xs: 4, md: 0 } }}>
            <Typography sx={sectionHeaderStyle}>Contact us</Typography>
            <Link href="mailto:lei@realix.ai" sx={footerTextStyle}>
              lei@realix.ai
            </Link>
            <Link
              href="https://docs.google.com/document/d/1o_E8k-29KdJeUqpxNcwe62zdkGrRIbVixQmNdshYvVo/edit?usp=sharing"
              target="_blank"
              rel="noopener"
              sx={footerTextStyle}
            >
              Privacy Policy
            </Link>
          </Box>

          <Box sx={{ textAlign: { xs: 'center', md: 'left' }, mb: { xs: 4, md: 0 } }}>
            <Typography sx={sectionHeaderStyle}>Online support</Typography>
            <Link
              href="https://www.linkedin.com/company/realixai/"
              target="_blank"
              rel="noopener"
              sx={footerTextStyle}
            >
              Linkedin
            </Link>
            <Link
              href="https://docs.google.com/document/d/19E6laMi6zo6jKr5uzHorwbskH3HyF9LAerTOEd__FK8/edit?usp=sharing"
              target="_blank"
              rel="noopener"
              sx={footerTextStyle}
            >
              Terms & Conditions
            </Link>
          </Box>
        </Box>

        <Box sx={{ mt: 1, textAlign: { sx: 'center', md: 'left' } }}>
          <Typography sx={{ ...footerTextStyle, color: 'white', mb: 0 }}>
            © 2025. All rights reserved
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
